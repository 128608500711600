$(".depoimentos__content").slick({
  slidesToShow: 2,
  autoplay: false,
  slidesToScroll: 1,
  arrows: true,
  dots: true,
  infinite: false,
  responsive: [
    {
      breakpoint: 999,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
});

document.addEventListener("DOMContentLoaded", function () {
  let lazyVideos = [].slice.call(document.querySelectorAll("video[data-src]"));

  if ("IntersectionObserver" in window) {
    let lazyVideoObserver = new IntersectionObserver(function (
      entries,
      observer
    ) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (let source in video.target.children) {
            let videoSource = video.target.children[source];
            if (
              typeof videoSource.tagName === "string" &&
              videoSource.tagName === "SOURCE"
            ) {
              videoSource.src = video.target.dataset.src;
            }
          }

          video.target.load();
          video.target.removeAttribute("data-src");
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function (video) {
      lazyVideoObserver.observe(video);
    });
  }
});
